import UtilDate from "../utils/UtilDate";
import UtilNumber from "../utils/UtilNumber";

export const tableColumnsTotalScheme = {
    nameOffer: {
        label: "Название предложения",
        prop: "nameOffer",
        isSlot: true,
        isSticky: true,
        isStickyLeft: true,
        columnStyles: {
            position: "sticky",
            left: "0",
            zIndex: "1",
            "box-shadow": "5px 0 5px 0 rgba(0, 0, 0, 0.05)",
        },
    },
    nameKit: {
        label: "Название набора",
        prop: "nameKit",
        isSticky: true,
        isStickyLeft: true,
        columnStyles: {
            position: "sticky",
            left: "0",
            zIndex: "1",
            "box-shadow": "5px 0 5px 0 rgba(0, 0, 0, 0.05)",
        },
    },
    mapMatchStatus: {
        label: "Сверено с Oohdesk",
        prop: "mapMatchStatus",
        apiProp: "map_match_status",
        isSlot: true,
        columnStyles: {
            width: "140px",
            padding: '0'
        },
        classes: ["map-match-status"],
    },
    isRevised: {
        label: "Сверено с оператором",
        prop: "isRevised",
        apiProp: "is_revised",
        isSlot: true,
        columnStyles: {
            width: "90px",
            display: "flex",
            "justify-content": "center",
        },
        classes: ["offer-price-cell"],
    },
    actionsOffer: {
        label: "Действия",
        prop: "actionsOffer",
        isSticky: true,
        isStickyRight: true,
        isSlot: true,
        isAction: true,
        isSlotHeader: true,
        columnStyles: {
            position: "sticky",
            right: "0",
            zIndex: "1",
            width: "150px",
            "box-shadow": "-5px 0 5px 0 rgba(0, 0, 0, 0.05)",
            display: "flex",
            "justify-content": "center",
        },
    },
    actionsKit: {
        label: "Действия",
        prop: "actionsKit",
        isSticky: true,
        isStickyRight: true,
        isSlot: true,
        isAction: true,
        isSlotHeader: true,
        columnStyles: {
            position: "sticky",
            right: "0",
            zIndex: "1",
            width: "110px",
            "box-shadow": "-5px 0 5px 0 rgba(0, 0, 0, 0.05)",
            display: "flex",
            "justify-content": "center",
        },
    },
    actionsApproved: {
        label: "Действия",
        prop: "actionsApproved",
        isSticky: true,
        isStickyRight: true,
        isSlot: true,
        isAction: true,
        isSlotHeader: true,
        columnStyles: {
            position: "sticky",
            right: "0",
            zIndex: "1",
            width: "50px",
            padding: "0px",
            "box-shadow": "-5px 0 5px 0 rgba(0, 0, 0, 0.05)",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
        },
    },
    actionsFact: {
        label: "Действия",
        prop: "actionsFact",
        isSticky: true,
        isStickyRight: true,
        isSlot: true,
        isAction: true,
        isSlotHeader: true,
        columnStyles: {
            position: "sticky",
            right: "0",
            zIndex: "1",
            width: "50px",
            padding: "0px",
            "box-shadow": "-5px 0 5px 0 rgba(0, 0, 0, 0.05)",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
        },
    },
    actionsMore: {
        label: "Действия",
        prop: "actionsMore",
        isSticky: true,
        isStickyRight: true,
        isSlot: true,
        isAction: true,
        isSlotHeader: true,
        columnStyles: {
            position: "sticky",
            right: "0",
            zIndex: "1",
            width: "110px",
            "box-shadow": "-5px 0 5px 0 rgba(0, 0, 0, 0.05)",
            display: "flex",
            "justify-content": "center",
        },
    },
    priceGroupTags: {
        label: "Тэги",
        prop: "priceGroupTags",
    },
    cities: {
        label: "Города",
        prop: "cities",
    },
    priceBudgetTotal: {
        label: "Общий бюджет без НДС",
        prop: "priceBudgetTotal",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    priceBudgetAgencyPrice: {
        label: "Цена аренды без НДС",
        prop: "priceBudgetAgencyPrice",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    priceBudgetPrice: {
        label: "Прайс аренда без НДС",
        prop: "priceBudgetPrice",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    priceBudgetDiscount: {
        label: "Скидка",
        prop: "priceBudgetDiscount",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    priceBudgetPrintPrice: {
        label: "Печать без НДС",
        prop: "priceBudgetPrintPrice",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    priceBudgetInstallPrice: {
        label: "Монтаж без НДС",
        prop: "priceBudgetInstallPrice",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    priceBudgetSideCount: {
        label: "Кол-во сторон",
        prop: "priceBudgetSideCount",
    },
    user: {
        label: "Кому отправлено",
        prop: "user",
    },
    createdAt: {
        label: "Дата создания",
        prop: "createdAt",
    },
    updatedAt: {
        label: "Дата обновления",
        prop: "updatedAt",
    },
    id: {
        label: "ID",
        prop: "id",
        columnStyles: {
            width: "100px",
        },
    },
    adress: {
        label: "Адрес стороны",
        prop: "adress",
    },
    agencyPrice: {
        label: "Цена аренды без НДС",
        prop: "agencyPrice",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    price: {
        label: "Прайс аренда без НДС",
        prop: "price",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    discount: {
        label: "Скидка",
        prop: "discount",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    printPrice: {
        label: "Печать без НДС",
        prop: "printPrice",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    installPrice: {
        label: "Монтаж без НДС",
        prop: "installPrice",
        columnStyles: {
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    city: {
        label: "Город",
        apiProp: "city",
        prop: "city",
        columnStyles: {
            width: "130px",
        },
    },
    brand: {
        label: "Бренд",
        apiProp: "brand",
        prop: "brand",
        columnStyles: {
            width: "130px",
        },
    },
    address: {
        label: "Адрес",
        apiProp: "address",
        prop: "address",
        columnStyles: {
            width: "500px",
        },
    },
    supplierApprovedAndFact: {
        label: "Оператор",
        apiProp: "supplier",
        prop: "supplier",
        columnStyles: {
            width: "110px",
        },
    },
    gidApprovedAndFact: {
        label: "GID",
        apiProp: "gid",
        prop: "gid",
        columnStyles: {
            width: "120px",
        },
    },
    kind: {
        label: "Вид",
        apiProp: "kind",
        prop: "kind",
        columnStyles: {
            width: "80px",
        },
    },
    type: {
        label: "Тип",
        apiProp: "type",
        prop: "type",
        columnStyles: {
            width: "120px",
        },
    },
    material: {
        label: "Материал",
        apiProp: "material",
        prop: "material",
        columnStyles: {
            width: "120px",
        },
    },
    format: {
        label: "Формат",
        apiProp: "format",
        prop: "format",
        columnStyles: {
            width: "80px",
        },
    },
    side: {
        label: "Сторона",
        apiProp: "side",
        prop: "side",
        columnStyles: {
            width: "80px",
        },
    },
    client: {
        label: "Бренд",
        apiProp: "client",
        prop: "client",
        columnStyles: {
            width: "120px",
        },
    },
    owner: {
        label: "Владелец",
        apiProp: "owner",
        prop: "owner",
        columnStyles: {
            width: "120px",
        },
    },
    video_block_clients: {
        label: "Выходов в блоке для digital/Кол-во для статики",
        apiProp: "video_block_clients",
        prop: "video_block_clients",
        columnStyles: {
            width: "120px",
        },
    },
    date_ac_start: {
        label: "Дата окончания РК",
        apiProp: "date_ac_start",
        prop: "date_ac_start",
        columnStyles: {
            width: "120px",
        },
    },
    date_ac_end: {
        label: "Дата старта РК",
        apiProp: "date_ac_end",
        prop: "date_ac_end",
        columnStyles: {
            width: "120px",
        },
    },
    direction: {
        label: "Направление",
        apiProp: "direction",
        prop: "direction",
        columnStyles: {
            width: "120px",
        },
    },
    date: {
        label: "Дата",
        apiProp: "date",
        prop: "date",
        columnStyles: {
            width: "100px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    count_days_ac: {
        label: "Кол-во дней",
        apiProp: "count_days_ac",
        prop: "count_days_ac",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    rent_price: {
        label: "Аренда Прайс",
        apiProp: "rent_price",
        prop: "rent_price",
        columnStyles: {
            width: "100px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    rent_discount: {
        label: "Аренда скидка",
        apiProp: "rent_discount",
        prop: "rent_discount",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    rent_tax: {
        label: "Аренда НАЛОГ",
        apiProp: "rent_tax",
        prop: "rent_tax",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    rent_cost: {
        label: "Аренда Себестоимость",
        apiProp: "rent_cost",
        prop: "rent_cost",
        columnStyles: {
            width: "110px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    rent_tax_price: {
        label: "Аренда НДС",
        apiProp: "rent_tax_price",
        prop: "rent_tax_price",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    rent_cost_without_tax: {
        label: "Аренда Себестоимость без НДС",
        apiProp: "rent_cost_without_tax",
        prop: "rent_cost_without_tax",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    supplier_entity: {
        label: "Аренда Юр лицо подрядчика",
        prop: "supplier_entity",
        apiProp: "supplier_entity",
        columnStyles: {
            width: "190px",
        },
    },
    "print_supplier_entity|1": {
        label: "Печать Юр лицо подрядчика 1",
        prop: "print_supplier_entity|1",
        apiProp: "print_supplier_entity|1",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_supplier_entity|2": {
        label: "Печать Юр лицо подрядчика 2",
        prop: "print_supplier_entity|2",
        apiProp: "print_supplier_entity|2",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_supplier_entity|3": {
        label: "Печать Юр лицо подрядчика 3",
        prop: "print_supplier_entity|3",
        apiProp: "print_supplier_entity|3",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_supplier_entity|1": {
        label: "Монтаж Юр лицо подрядчика 1",
        prop: "install_supplier_entity|1",
        apiProp: "install_supplier_entity|1",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_supplier_entity|2": {
        label: "Монтаж Юр лицо подрядчика 2",
        prop: "install_supplier_entity|2",
        apiProp: "install_supplier_entity|2",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_supplier_entity|3": {
        label: "Монтаж Юр лицо подрядчика 3",
        prop: "install_supplier_entity|3",
        apiProp: "install_supplier_entity|3",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_supplier|1": {
        label: "Печать подрядчик 1",
        apiProp: "print_supplier|1",
        prop: "print_supplier|1",
        columnStyles: {
            width: "110px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_supplier|2": {
        label: "Печать подрядчик 2",
        apiProp: "print_supplier|2",
        prop: "print_supplier|2",
        columnStyles: {
            width: "110px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_supplier|3": {
        label: "Печать подрядчик 3",
        apiProp: "print_supplier|3",
        prop: "print_supplier|3",
        columnStyles: {
            width: "110px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_tax|1": {
        label: "Печать Налог ПОДРЯДЧИК 1",
        apiProp: "print_tax|1",
        prop: "print_tax|1",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_tax|2": {
        label: "Печать Налог ПОДРЯДЧИК 2",
        apiProp: "print_tax|2",
        prop: "print_tax|2",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_tax|3": {
        label: "Печать Налог ПОДРЯДЧИК 3",
        apiProp: "print_tax|3",
        prop: "print_tax|3",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_cost|1": {
        label: "Печать Себестоимость ПОДРЯДЧИК 1",
        apiProp: "print_cost|1",
        prop: "print_cost|1",
        columnStyles: {
            width: "130px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_cost|2": {
        label: "Печать Себестоимость ПОДРЯДЧИК 2",
        apiProp: "print_cost|2",
        prop: "print_cost|2",
        columnStyles: {
            width: "130px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_cost|3": {
        label: "Печать Себестоимость ПОДРЯДЧИК 3",
        apiProp: "print_cost|3",
        prop: "print_cost|3",
        columnStyles: {
            width: "130px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_tax_price|1": {
        label: "Печать НДС 1",
        apiProp: "print_tax_price|1",
        prop: "print_tax_price|1",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_tax_price|2": {
        label: "Печать НДС 2",
        apiProp: "print_tax_price|2",
        prop: "print_tax_price|2",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_tax_price|3": {
        label: "Печать НДС 3",
        apiProp: "print_tax_price|3",
        prop: "print_tax_price|3",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_cost_without_tax|1": {
        label: "Печать Себестоимость без НДС 1",
        apiProp: "print_cost_without_tax|1",
        prop: "print_cost_without_tax|1",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_cost_without_tax|2": {
        label: "Печать Себестоимость без НДС 2",
        apiProp: "print_cost_without_tax|2",
        prop: "print_cost_without_tax|2",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "print_cost_without_tax|3": {
        label: "Печать Себестоимость без НДС 3",
        apiProp: "print_cost_without_tax|3",
        prop: "print_cost_without_tax|3",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_supplier|1": {
        label: "Монтаж подрядчик 1",
        apiProp: "install_supplier|1",
        prop: "install_supplier|1",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_supplier|2": {
        label: "Монтаж подрядчик 2",
        apiProp: "install_supplier|2",
        prop: "install_supplier|2",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_supplier|3": {
        label: "Монтаж подрядчик 3",
        apiProp: "install_supplier|3",
        prop: "install_supplier|3",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_tax|1": {
        label: "Монтаж Налог ПОДРЯДЧИК 1",
        apiProp: "install_tax|1",
        prop: "install_tax|1",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_tax|2": {
        label: "Монтаж Налог ПОДРЯДЧИК 2",
        apiProp: "install_tax|2",
        prop: "install_tax|2",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_tax|3": {
        label: "Монтаж Налог ПОДРЯДЧИК 3",
        apiProp: "install_tax|3",
        prop: "install_tax|3",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_cost|1": {
        label: "Монтаж Себестоимость ПОДРЯДЧИК 1",
        apiProp: "install_cost|1",
        prop: "install_cost|1",
        columnStyles: {
            width: "130px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_cost|2": {
        label: "Монтаж Себестоимость ПОДРЯДЧИК 2",
        apiProp: "install_cost|2",
        prop: "install_cost|2",
        columnStyles: {
            width: "130px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_cost|3": {
        label: "Монтаж Себестоимость ПОДРЯДЧИК 3",
        apiProp: "install_cost|3",
        prop: "install_cost|3",
        columnStyles: {
            width: "130px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_tax_price|1": {
        label: "Монтаж НДС 1",
        apiProp: "install_tax_price|1",
        prop: "install_tax_price|1",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_tax_price|2": {
        label: "Монтаж НДС 2",
        apiProp: "install_tax_price|2",
        prop: "install_tax_price|2",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_tax_price|3": {
        label: "Монтаж НДС 3",
        apiProp: "install_tax_price|3",
        prop: "install_tax_price|3",
        columnStyles: {
            width: "80px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_cost_without_tax|1": {
        label: "Монтаж Себестоимость без НДС 1",
        apiProp: "install_cost_without_tax|1",
        prop: "install_cost_without_tax|1",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_cost_without_tax|2": {
        label: "Монтаж Себестоимость без НДС 2",
        apiProp: "install_cost_without_tax|2",
        prop: "install_cost_without_tax|2",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    "install_cost_without_tax|3": {
        label: "Монтаж Себестоимость без НДС 3",
        apiProp: "install_cost_without_tax|3",
        prop: "install_cost_without_tax|3",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    sum_cost: {
        label: "Себестоимость",
        apiProp: "sum_cost",
        prop: "sum_cost",
        columnStyles: {
            width: "120px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    sum_tax_price: {
        label: "НДС",
        apiProp: "sum_tax_price",
        prop: "sum_tax_price",
        columnStyles: {
            width: "100px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
    sum_cost_without_tax: {
        label: "Себестоимость без НДС",
        apiProp: "sum_cost_without_tax",
        prop: "sum_cost_without_tax",
        columnStyles: {
            width: "105px",
            display: "flex",
            "justify-content": "end",
        },
        classes: ["offer-price-cell"],
    },
};

export const tableColumnsScheme = {
    offers: [
        {
            prop: "nameOffer",
            isShow: true,
        },
        {
            prop: "priceGroupTags",
            isShow: true,
        },
        {
            prop: "cities",
            isShow: true,
        },
        {
            prop: "priceBudgetTotal",
            isShow: true,
        },
        {
            prop: "priceBudgetAgencyPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetDiscount",
            isShow: true,
        },
        {
            prop: "priceBudgetPrintPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetInstallPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetSideCount",
            isShow: true,
        },
        {
            prop: "user",
            isShow: true,
        },
        {
            prop: "createdAt",
            isShow: true,
        },
        {
            prop: "updatedAt",
            isShow: true,
        },
        {
            prop: "id",
            isShow: true,
        },
        {
            prop: "actionsOffer",
            isShow: true,
        },
    ],
    kits: [
        {
            prop: "nameKit",
            isShow: true,
        },
        {
            prop: "cities",
            isShow: true,
        },
        {
            prop: "priceBudgetTotal",
            isShow: true,
        },
        {
            prop: "priceBudgetAgencyPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetDiscount",
            isShow: true,
        },
        {
            prop: "priceBudgetPrintPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetInstallPrice",
            isShow: true,
        },
        {
            prop: "priceBudgetSideCount",
            isShow: true,
        },
        {
            prop: "createdAt",
            isShow: true,
        },
        {
            prop: "updatedAt",
            isShow: true,
        },
        {
            prop: "id",
            isShow: true,
        },
        {
            prop: "actionsKit",
            isShow: true,
        },
    ],
    approved: [
        {
            prop: "mapMatchStatus",
            apiProp: "map_match_status",
            isShow: true,
        },
        {
            prop: "isRevised",
            apiProp: "is_revised",
            isShow: true,
        },
        {
            prop: "brand",
            apiProp: "brand",
            isShow: true,
        },
        {
            prop: "city",
            apiProp: "city.name",
            isShow: true,
        },
        {
            prop: "address",
            apiProp: "address",
            isShow: true,
        },
        {
            prop: "supplierApprovedAndFact",
            apiProp: "supplier",
            isShow: true,
        },
        {
            prop: "gidApprovedAndFact",
            apiProp: "gid",
            isShow: true,
        },
        {
            prop: "kind",
            apiProp: "kind",
            isShow: true,
        },
        {
            prop: "type",
            apiPop: "type",
            isShow: true,
        },
        {
            prop: "material",
            apiProp: "material",
            isShow: true,
        },
        {
            prop: "format",
            apiProp: "format",
            isShow: true,
        },
        {
            prop: "side",
            apiProp: "side",
            isShow: true,
        },
        {
            prop: "client",
            apiProp: "client",
            isShow: true,
        },
        {
            prop: "owner",
            apiProp: "owner",
            isShow: true,
        },
        {
            prop: "video_block_clients",
            apiProp: "video_block_clients",
            isShow: true,
        },
        {
            prop: "date_ac_start",
            apiProp: "date_ac_start",
            isShow: true,
        },
        {
            prop: "date_ac_end",
            apiProp: "date_ac_end",
            isShow: true,
        },
        {
            prop: "date",
            apiProp: "date",
            isShow: true,
        },
        {
            prop: "count_days_ac",
            apiProp: "count_days_ac",
            isShow: true,
        },
        {
            prop: "supplier_entity",
            apiProp: "supplier_entity",
            isShow: true,
        },
        {
            prop: "print_supplier_entity|1",
            apiProp: "print_supplier_entity|1",
            isShow: true,
        },
        {
            prop: "print_supplier_entity|2",
            apiProp: "print_supplier_entity|2",
            isShow: false,
        },
        {
            prop: "print_supplier_entity|3",
            apiProp: "print_supplier_entity|3",
            isShow: false,
        },
        {
            prop: "install_supplier_entity|1",
            apiProp: "install_supplier_entity|1",
            isShow: true,
        },
        {
            prop: "install_supplier_entity|2",
            apiProp: "install_supplier_entity|2",
            isShow: false,
        },
        {
            prop: "install_supplier_entity|3",
            apiProp: "install_supplier_entity|3",
            isShow: false,
        },
        {
            prop: "rent_price",
            apiProp: "rent_price",
            isShow: true,
        },
        {
            prop: "rent_discount",
            apiProp: "rent_discount",
            isShow: true,
        },
        {
            prop: "rent_tax",
            apiProp: "rent_tax",
            isShow: true,
        },
        {
            prop: "rent_cost",
            apiProp: "rent_cost",
            isShow: true,
        },
        {
            prop: "rent_tax_price",
            apiProp: "rent_tax_price",
            isShow: true,
        },
        {
            prop: "rent_cost_without_tax",
            apiProp: "rent_cost_without_tax",
            isShow: true,
        },
        {
            prop: "print_supplier|1",
            apiProp: "print_supplier|1",
            isShow: true,
        },
        {
            prop: "print_supplier|2",
            apiProp: "print_supplier|2",
            isShow: false,
        },
        {
            prop: "print_supplier|3",
            apiProp: "print_supplier|3",
            isShow: false,
        },
        {
            prop: "print_tax|1",
            apiProp: "print_tax|1",
            isShow: true,
        },
        {
            prop: "print_tax|2",
            apiProp: "print_tax|2",
            isShow: false,
        },
        {
            prop: "print_tax|3",
            apiProp: "print_tax|3",
            isShow: false,
        },
        {
            prop: "print_cost|1",
            apiProp: "print_cost|1",
            isShow: true,
        },
        {
            prop: "print_cost|2",
            apiProp: "print_cost|2",
            isShow: false,
        },
        {
            prop: "print_cost|3",
            apiProp: "print_cost|3",
            isShow: false,
        },
        {
            prop: "print_tax_price|1",
            apiProp: "print_tax_price|1",
            isShow: true,
        },
        {
            prop: "print_tax_price|2",
            apiProp: "print_tax_price|2",
            isShow: false,
        },
        {
            prop: "print_tax_price|3",
            apiProp: "print_tax_price|3",
            isShow: false,
        },
        {
            prop: "print_cost_without_tax|1",
            apiProp: "print_cost_without_tax|1",
            isShow: true,
        },
        {
            prop: "print_cost_without_tax|2",
            apiProp: "print_cost_without_tax|2",
            isShow: false,
        },
        {
            prop: "print_cost_without_tax|3",
            apiProp: "print_cost_without_tax|3",
            isShow: false,
        },
        {
            prop: "install_supplier|1",
            apiProp: "install_supplier|1",
            isShow: true,
        },
        {
            prop: "install_supplier|2",
            apiProp: "install_supplier|2",
            isShow: false,
        },
        {
            prop: "install_supplier|3",
            apiProp: "install_supplier|3",
            isShow: false,
        },
        {
            prop: "install_tax|1",
            apiProp: "install_tax|1",
            isShow: true,
        },
        {
            prop: "install_tax|2",
            apiProp: "install_tax|2",
            isShow: false,
        },
        {
            prop: "install_tax|3",
            apiProp: "install_tax|3",
            isShow: false,
        },
        {
            prop: "install_cost|1",
            apiProp: "install_cost|1",
            isShow: true,
        },
        {
            prop: "install_cost|2",
            apiProp: "install_cost|2",
            isShow: false,
        },
        {
            prop: "install_cost|3",
            apiProp: "install_cost|3",
            isShow: false,
        },
        {
            prop: "install_tax_price|1",
            apiProp: "install_tax_price|1",
            isShow: true,
        },
        {
            prop: "install_tax_price|2",
            apiProp: "install_tax_price|2",
            isShow: false,
        },
        {
            prop: "install_tax_price|3",
            apiProp: "install_tax_price|3",
            isShow: false,
        },
        {
            prop: "install_cost_without_tax|1",
            apiProp: "install_cost_without_tax|1",
            isShow: true,
        },
        {
            prop: "install_cost_without_tax|2",
            apiProp: "install_cost_without_tax|2",
            isShow: false,
        },
        {
            prop: "install_cost_without_tax|3",
            apiProp: "install_cost_without_tax|3",
            isShow: false,
        },
        {
            prop: "sum_cost",
            apiProp: "sum_cost",
            isShow: true,
        },
        {
            prop: "sum_tax_price",
            apiProp: "sum_tax_price",
            isShow: true,
        },
        {
            prop: "sum_cost_without_tax",
            apiProp: "sum_cost_without_tax",
            isShow: true,
        },
        {
            prop: "actionsApproved",
            apiProp: "actionsApproved",
            isShow: true,
            isAction: true
        },
    ],
    fact: [
        {
            prop: "mapMatchStatus",
            apiProp: "map_match_status",
            isShow: true,
        },
        {
            prop: "isRevised",
            apiProp: "is_revised",
            isShow: true,
        },
        {
            prop: "brand",
            apiProp: "brand",
            isShow: true,
        },
        {
            prop: "city",
            apiProp: "city.name",
            isShow: true,
        },
        {
            prop: "address",
            apiProp: "address",
            isShow: true,
        },
        {
            prop: "supplierApprovedAndFact",
            apiProp: "supplier",
            isShow: true,
        },
        {
            prop: "gidApprovedAndFact",
            apiProp: "gid",
            isShow: true,
        },
        {
            prop: "kind",
            apiProp: "kind",
            isShow: true,
        },
        {
            prop: "type",
            apiPop: "type",
            isShow: true,
        },
        {
            prop: "material",
            apiProp: "material",
            isShow: true,
        },
        {
            prop: "format",
            apiProp: "format",
            isShow: true,
        },
        {
            prop: "side",
            apiProp: "side",
            isShow: true,
        },
        {
            prop: "client",
            apiProp: "client",
            isShow: true,
        },
        {
            prop: "video_block_clients",
            apiProp: "video_block_clients",
            isShow: true,
        },
        {
            prop: "date_ac_start",
            apiProp: "date_ac_start",
            isShow: true,
        },
        {
            prop: "date_ac_end",
            apiProp: "date_ac_end",
            isShow: true,
        },
        {
            prop: "date",
            apiProp: "date",
            isShow: true,
        },
        {
            prop: "count_days_ac",
            apiProp: "count_days_ac",
            isShow: true,
        },
        {
            prop: "direction",
            apiProp: "direction",
            isShow: true,
        },
        {
            prop: "supplier_entity",
            apiProp: "supplier_entity",
            isShow: true,
        },
        {
            prop: "print_supplier_entity|1",
            apiProp: "print_supplier_entity|1",
            isShow: true,
        },
        {
            prop: "print_supplier_entity|2",
            apiProp: "print_supplier_entity|2",
            isShow: false,
        },
        {
            prop: "print_supplier_entity|3",
            apiProp: "print_supplier_entity|3",
            isShow: false,
        },
        {
            prop: "install_supplier_entity|1",
            apiProp: "install_supplier_entity|1",
            isShow: true,
        },
        {
            prop: "install_supplier_entity|2",
            apiProp: "install_supplier_entity|2",
            isShow: false,
        },
        {
            prop: "install_supplier_entity|3",
            apiProp: "install_supplier_entity|3",
            isShow: false,
        },
        {
            prop: "owner",
            apiProp: "owner",
            isShow: true,
        },
        {
            prop: "rent_price",
            apiProp: "rent_price",
            isShow: true,
        },
        {
            prop: "rent_discount",
            apiProp: "rent_discount",
            isShow: true,
        },
        {
            prop: "rent_tax",
            apiProp: "rent_tax",
            isShow: true,
        },
        {
            prop: "rent_cost",
            apiProp: "rent_cost",
            isShow: true,
        },
        {
            prop: "rent_tax_price",
            apiProp: "rent_tax_price",
            isShow: true,
        },
        {
            prop: "rent_cost_without_tax",
            apiProp: "rent_cost_without_tax",
            isShow: true,
        },
        {
            prop: "print_supplier|1",
            apiProp: "print_supplier|1",
            isShow: true,
        },
        {
            prop: "print_supplier|2",
            apiProp: "print_supplier|2",
            isShow: false,
        },
        {
            prop: "print_supplier|3",
            apiProp: "print_supplier|3",
            isShow: false,
        },
        {
            prop: "print_tax|1",
            apiProp: "print_tax|1",
            isShow: true,
        },
        {
            prop: "print_tax|2",
            apiProp: "print_tax|2",
            isShow: false,
        },
        {
            prop: "print_tax|3",
            apiProp: "print_tax|3",
            isShow: false,
        },
        {
            prop: "print_cost|1",
            apiProp: "print_cost|1",
            isShow: true,
        },
        {
            prop: "print_cost|2",
            apiProp: "print_cost|2",
            isShow: false,
        },
        {
            prop: "print_cost|3",
            apiProp: "print_cost|3",
            isShow: false,
        },
        {
            prop: "print_tax_price|1",
            apiProp: "print_tax_price|1",
            isShow: true,
        },
        {
            prop: "print_tax_price|2",
            apiProp: "print_tax_price|2",
            isShow: false,
        },
        {
            prop: "print_tax_price|3",
            apiProp: "print_tax_price|3",
            isShow: false,
        },
        {
            prop: "print_cost_without_tax|1",
            apiProp: "print_cost_without_tax|1",
            isShow: true,
        },
        {
            prop: "print_cost_without_tax|2",
            apiProp: "print_cost_without_tax|2",
            isShow: false,
        },
        {
            prop: "print_cost_without_tax|3",
            apiProp: "print_cost_without_tax|3",
            isShow: false,
        },
        {
            prop: "install_supplier|1",
            apiProp: "install_supplier|1",
            isShow: true,
        },
        {
            prop: "install_supplier|2",
            apiProp: "install_supplier|2",
            isShow: false,
        },
        {
            prop: "install_supplier|3",
            apiProp: "install_supplier|3",
            isShow: false,
        },
        {
            prop: "install_tax|1",
            apiProp: "install_tax|1",
            isShow: true,
        },
        {
            prop: "install_tax|2",
            apiProp: "install_tax|2",
            isShow: false,
        },
        {
            prop: "install_tax|3",
            apiProp: "install_tax|3",
            isShow: false,
        },
        {
            prop: "install_cost|1",
            apiProp: "install_cost|1",
            isShow: true,
        },
        {
            prop: "install_cost|2",
            apiProp: "install_cost|2",
            isShow: false,
        },
        {
            prop: "install_cost|3",
            apiProp: "install_cost|3",
            isShow: false,
        },
        {
            prop: "install_tax_price|1",
            apiProp: "install_tax_price|1",
            isShow: true,
        },
        {
            prop: "install_tax_price|2",
            apiProp: "install_tax_price|2",
            isShow: false,
        },
        {
            prop: "install_tax_price|3",
            apiProp: "install_tax_price|3",
            isShow: false,
        },
        {
            prop: "install_cost_without_tax|1",
            apiProp: "install_cost_without_tax|1",
            isShow: true,
        },
        {
            prop: "install_cost_without_tax|2",
            apiProp: "install_cost_without_tax|2",
            isShow: false,
        },
        {
            prop: "install_cost_without_tax|3",
            apiProp: "install_cost_without_tax|3",
            isShow: false,
        },
        {
            prop: "sum_cost",
            apiProp: "sum_cost",
            isShow: true,
        },
        {
            prop: "sum_tax_price",
            apiProp: "sum_tax_price",
            isShow: true,
        },
        {
            prop: "sum_cost_without_tax",
            apiProp: "sum_cost_without_tax",
            isShow: true,
        },
        {
            prop: "actionsFact",
            apiProp: "actionsFact",
            isShow: true,
            isAction: true
        },
    ],
    more: [
        {
            prop: "mapMatchStatus",
            apiProp: "map_match_status",
            isShow: true,
        },
        {
            prop: "isRevised",
            apiProp: "is_revised",
            isShow: true,
        },
        {
            prop: "brand",
            apiProp: "brand",
            isShow: true,
        },
        {
            prop: "city",
            apiProp: "city.name",
            isShow: true,
        },
        {
            prop: "address",
            apiProp: "address",
            isShow: true,
        },
        {
            prop: "supplierApprovedAndFact",
            apiProp: "supplier",
            isShow: true,
        },
        {
            prop: "gidApprovedAndFact",
            apiProp: "gid",
            isShow: true,
        },
        {
            prop: "kind",
            apiProp: "kind",
            isShow: true,
        },
        {
            prop: "type",
            apiPop: "type",
            isShow: true,
        },
        {
            prop: "material",
            apiProp: "material",
            isShow: true,
        },
        {
            prop: "format",
            apiProp: "format",
            isShow: true,
        },
        {
            prop: "side",
            apiProp: "side",
            isShow: true,
        },
        {
            prop: "client",
            apiProp: "client",
            isShow: true,
        },
        {
            prop: "owner",
            apiProp: "owner",
            isShow: true,
        },
        {
            prop: "video_block_clients",
            apiProp: "video_block_clients",
            isShow: true,
        },
        {
            prop: "date_ac_start",
            apiProp: "date_ac_start",
            isShow: true,
        },
        {
            prop: "date_ac_end",
            apiProp: "date_ac_end",
            isShow: true,
        },
        {
            prop: "date",
            apiProp: "date",
            isShow: true,
        },
        {
            prop: "count_days_ac",
            apiProp: "count_days_ac",
            isShow: true,
        },
        {
            prop: "supplier_entity",
            apiProp: "supplier_entity",
            isShow: true,
        },
        {
            prop: "print_supplier_entity|1",
            apiProp: "print_supplier_entity|1",
            isShow: true,
        },
        {
            prop: "print_supplier_entity|2",
            apiProp: "print_supplier_entity|2",
            isShow: false,
        },
        {
            prop: "print_supplier_entity|3",
            apiProp: "print_supplier_entity|3",
            isShow: false,
        },
        {
            prop: "install_supplier_entity|1",
            apiProp: "install_supplier_entity|1",
            isShow: true,
        },
        {
            prop: "install_supplier_entity|2",
            apiProp: "install_supplier_entity|2",
            isShow: false,
        },
        {
            prop: "install_supplier_entity|3",
            apiProp: "install_supplier_entity|3",
            isShow: false,
        },
        {
            prop: "rent_price",
            apiProp: "rent_price",
            isShow: true,
        },
        {
            prop: "rent_discount",
            apiProp: "rent_discount",
            isShow: true,
        },
        {
            prop: "rent_tax",
            apiProp: "rent_tax",
            isShow: true,
        },
        {
            prop: "rent_cost",
            apiProp: "rent_cost",
            isShow: true,
        },
        {
            prop: "rent_tax_price",
            apiProp: "rent_tax_price",
            isShow: true,
        },
        {
            prop: "rent_cost_without_tax",
            apiProp: "rent_cost_without_tax",
            isShow: true,
        },
        {
            prop: "print_supplier|1",
            apiProp: "print_supplier|1",
            isShow: true,
        },
        {
            prop: "print_supplier|2",
            apiProp: "print_supplier|2",
            isShow: false,
        },
        {
            prop: "print_supplier|3",
            apiProp: "print_supplier|3",
            isShow: false,
        },
        {
            prop: "print_tax|1",
            apiProp: "print_tax|1",
            isShow: true,
        },
        {
            prop: "print_tax|2",
            apiProp: "print_tax|2",
            isShow: false,
        },
        {
            prop: "print_tax|3",
            apiProp: "print_tax|3",
            isShow: false,
        },
        {
            prop: "print_cost|1",
            apiProp: "print_cost|1",
            isShow: true,
        },
        {
            prop: "print_cost|2",
            apiProp: "print_cost|2",
            isShow: false,
        },
        {
            prop: "print_cost|3",
            apiProp: "print_cost|3",
            isShow: false,
        },
        {
            prop: "print_tax_price|1",
            apiProp: "print_tax_price|1",
            isShow: true,
        },
        {
            prop: "print_tax_price|2",
            apiProp: "print_tax_price|2",
            isShow: false,
        },
        {
            prop: "print_tax_price|3",
            apiProp: "print_tax_price|3",
            isShow: false,
        },
        {
            prop: "print_cost_without_tax|1",
            apiProp: "print_cost_without_tax|1",
            isShow: true,
        },
        {
            prop: "print_cost_without_tax|2",
            apiProp: "print_cost_without_tax|2",
            isShow: false,
        },
        {
            prop: "print_cost_without_tax|3",
            apiProp: "print_cost_without_tax|3",
            isShow: false,
        },
        {
            prop: "install_supplier|1",
            apiProp: "install_supplier|1",
            isShow: true,
        },
        {
            prop: "install_supplier|2",
            apiProp: "install_supplier|2",
            isShow: false,
        },
        {
            prop: "install_supplier|3",
            apiProp: "install_supplier|3",
            isShow: false,
        },
        {
            prop: "install_tax|1",
            apiProp: "install_tax|1",
            isShow: true,
        },
        {
            prop: "install_tax|2",
            apiProp: "install_tax|2",
            isShow: false,
        },
        {
            prop: "install_tax|3",
            apiProp: "install_tax|3",
            isShow: false,
        },
        {
            prop: "install_cost|1",
            apiProp: "install_cost|1",
            isShow: true,
        },
        {
            prop: "install_cost|2",
            apiProp: "install_cost|2",
            isShow: false,
        },
        {
            prop: "install_cost|3",
            apiProp: "install_cost|3",
            isShow: false,
        },
        {
            prop: "install_tax_price|1",
            apiProp: "install_tax_price|1",
            isShow: true,
        },
        {
            prop: "install_tax_price|2",
            apiProp: "install_tax_price|2",
            isShow: false,
        },
        {
            prop: "install_tax_price|3",
            apiProp: "install_tax_price|3",
            isShow: false,
        },
        {
            prop: "install_cost_without_tax|1",
            apiProp: "install_cost_without_tax|1",
            isShow: true,
        },
        {
            prop: "install_cost_without_tax|2",
            apiProp: "install_cost_without_tax|2",
            isShow: false,
        },
        {
            prop: "install_cost_without_tax|3",
            apiProp: "install_cost_without_tax|3",
            isShow: false,
        },
        {
            prop: "sum_cost",
            apiProp: "sum_cost",
            isShow: true,
        },
        {
            prop: "sum_tax_price",
            apiProp: "sum_tax_price",
            isShow: true,
        },
        {
            prop: "sum_cost_without_tax",
            apiProp: "sum_cost_without_tax",
            isShow: true,
        },
        {
            prop: "actionsApproved",
            apiProp: "actionsApproved",
            isShow: true,
            isAction: true
        },
    ],
};

export const tableRowsValuesTotalScheme = {
    nameOffer: (row) => String(row?.name ?? ""),
    nameKit: (row) => String(row?.name ?? ""),
    actionsOffer: () => "Завершить",
    actionsKit: () => "Отправить",
    actionsApproved: () => "Отправить",
    actionsFact: () => {},
    actionsMore: () => {},
    mapMatchStatus: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.map_match_status_label ?? "");
    },
    isRevised: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return Boolean(price?.is_revised ?? false);
    },
    priceGroupTags: (row) => {
        const priceGroupTags = row?.priceGroupTags ?? [];
        const priceGroupTagsValue = priceGroupTags
            .map((priceGroupTag) => String(priceGroupTag?.value ?? ""))
            .join(", ");
        return priceGroupTagsValue;
    },
    cities: (row) => {
        const priceCities = row?.priceCities ?? [];
        const priceCitiesValue = priceCities
            .map((priceCity) => String(priceCity?.city_name ?? ""))
            .join(", ");
        return priceCitiesValue;
    },
    createdAt: (row) => {
        const createdAt = String(row?.created_at ?? "");
        return UtilDate.getLocaleDate(createdAt);
    },
    updatedAt: (row) => {
        const updatedAt = String(row?.updated_at ?? "");
        return UtilDate.getLocaleDate(updatedAt);
    },
    user: (row) => {
        const user = row?.user ?? {};
        return String(user?.email ?? "");
    },
    priceBudgetSideCount: (row) => {
        const priceBudget = row?.priceBudget ?? {};
        return String(priceBudget?.side_count ?? "");
    },
    priceBudgetTotal: (row) => {
        const priceBudget = row?.priceBudget ?? {};
        const agencyPrice = parseFloat(
            priceBudget?.agency_price_without_tax ?? 0
        );
        const installPrice = parseFloat(
            priceBudget?.install_price_without_tax ?? 0
        );
        const printPrice = parseFloat(
            priceBudget?.print_price_without_tax ?? 0
        );
        const totalPrice = agencyPrice + installPrice + printPrice;
        return totalPrice === 0 ? "" : UtilNumber.toPrice(totalPrice);
    },
    priceBudgetAgencyPrice: (row) => {
        const priceBudget = row?.priceBudget ?? {};
        const agencyPrice = parseFloat(
            priceBudget?.agency_price_without_tax ?? ""
        );
        return UtilNumber.toPrice(agencyPrice);
    },
    priceBudgetPrice: (row) => {
        const priceBudget = row?.priceBudget ?? {};
        const price = parseFloat(priceBudget?.price_without_tax ?? "");
        return UtilNumber.toPrice(price);
    },
    priceBudgetDiscount: (row) => {
        const priceBudget = row?.priceBudget ?? {};
        const discount = parseFloat(priceBudget?.discount ?? "");
        return UtilNumber.toPercent(discount);
    },
    priceBudgetPrintPrice: (row) => {
        const priceBudget = row?.priceBudget ?? {};
        const printPrice = parseFloat(
            priceBudget?.print_price_without_tax ?? ""
        );
        return UtilNumber.toPrice(printPrice);
    },
    priceBudgetInstallPrice: (row) => {
        const priceBudget = row?.priceBudget ?? {};
        const installPrice = parseFloat(
            priceBudget?.install_price_without_tax ?? ""
        );
        return UtilNumber.toPrice(installPrice);
    },
    id: (row) => String(row?.id ?? ""),
    adress: (row) => {
        const userInfo = row?.sideUserInfo ?? {};
        return String(userInfo?.realAddress ?? "");
    },
    statusUser: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.user_status_label ?? "");
    },
    supplier: (row) => {
        const propertyLinks = row?.propertyLinks ?? {};
        return String(propertyLinks?.supp_id ?? "");
    },
    gid: (row) => {
        const userInfo = row?.sideUserInfo ?? {};
        return String(userInfo?.supp_item_id ?? "");
    },
    guid: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.id ?? "");
    },
    city: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const city = price?.city ?? {};
        return String(city?.name ?? "");
    },
    side: (row) => String(row?.code ?? ""),
    agencyPrice: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const agencyPrice = parseFloat(price?.agency_price ?? "");
        return UtilNumber.toPrice(agencyPrice);
    },
    price: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const priceValue = parseFloat(price?.price_without_tax ?? "");
        return UtilNumber.toPrice(priceValue);
    },
    discount: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const discount = parseFloat(price?.discount ?? "");
        return UtilNumber.toPercent(discount);
    },
    printPrice: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const printPrice = parseFloat(price?.print_price_without_tax ?? "");
        return UtilNumber.toPrice(printPrice);
    },
    installPrice: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const installPrice = parseFloat(price?.install_price_without_tax ?? "");
        return UtilNumber.toPrice(installPrice);
    },
    agree: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return Boolean(price?.approved ?? false);
    },
    address: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.address ?? "");
    },
    status: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.status_label ?? "");
    },
    supplierApprovedAndFact: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.supplier ?? "");
    },
    gidApprovedAndFact: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.gid ?? "");
    },
    brand: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.brand ?? "");
    },
    client: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.client ?? "");
    },
    contract_type: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.contract_type ?? "");
    },
    supplier_entity: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.supplier_entity ?? "");
    },
    "print_supplier_entity|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_supplier_entity|1"] ?? "");
    },
    "print_supplier_entity|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_supplier_entity|2"] ?? "");
    },
    "print_supplier_entity|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_supplier_entity|3"] ?? "");
    },
    "install_supplier_entity|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["install_supplier_entity|1"] ?? "");
    },
    "install_supplier_entity|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["install_supplier_entity|2"] ?? "");
    },
    "install_supplier_entity|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["install_supplier_entity|3"] ?? "");
    },
    format: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.format ?? "");
    },
    kind: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.kind ?? "");
    },
    agency_name: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.agency_name ?? "");
    },
    count_days_ac: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.count_days_ac ?? "");
    },
    date: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const priceDate = price?.date ?? "";
        return UtilDate.getLocaleDate(priceDate);
    },
    date_ac_start: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const priceDateAcStart = price?.date_ac_start ?? "";
        return UtilDate.getLocaleDate(priceDateAcStart);
    },
    date_ac_end: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const priceDateAcEnd = price?.date_ac_end ?? "";
        return UtilDate.getLocaleDate(priceDateAcEnd);
    },
    type: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.type ?? "");
    },
    material: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.material ?? "");
    },
    count_days_ac_end: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.count_days_ac_end ?? "");
    },
    count_days_ac_start: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.count_days_ac_start ?? "");
    },
    direction: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.direction ?? "");
    },
    media: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.media ?? "");
    },
    owner: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.owner ?? "");
    },
    video_block_clients: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.video_block_clients ?? "");
    },
    rent_price: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const rentPrice = price?.rent_price ?? "";
        return UtilNumber.toPrice(rentPrice);
    },
    rent_discount: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const rentDiscount = price?.rent_discount ?? "";
        return UtilNumber.toPercent(rentDiscount);
    },
    rent_tax: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.rent_tax ?? "");
    },
    rent_cost: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const rentCost = price?.rent_cost ?? "";
        return UtilNumber.toPrice(rentCost);
    },
    rent_cost_without_tax: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const rentCostWithoutTax = price?.rent_cost_without_tax ?? "";
        return UtilNumber.toPrice(rentCostWithoutTax);
    },
    rent_tax_price: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const rentTaxPrice = price?.rent_tax_price ?? "";
        return UtilNumber.toPrice(rentTaxPrice);
    },
    sum_cost_without_tax: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const sumCostWithoutTax = price?.sum_cost_without_tax ?? "";
        return UtilNumber.toPrice(sumCostWithoutTax);
    },
    sum_cost: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const sumCost = price?.sum_cost ?? "";
        return UtilNumber.toPrice(sumCost);
    },
    sum_tax_price: (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        const sumTaxPrice = price?.sum_tax_price ?? "";
        return UtilNumber.toPrice(sumTaxPrice);
    },
    "install_cost_without_tax|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_cost_without_tax|1"] ?? "");
    },
    "install_cost_without_tax|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_cost_without_tax|2"] ?? "");
    },
    "install_cost_without_tax|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_cost_without_tax|3"] ?? "");
    },
    "install_cost|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_cost|1"] ?? "");
    },
    "install_cost|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_cost|2"] ?? "");
    },
    "install_cost|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_cost|3"] ?? "");
    },
    "install_supplier|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_supplier|1"] ?? "");
    },
    "install_supplier|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_supplier|2"] ?? "");
    },
    "install_supplier|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_supplier|3"] ?? "");
    },
    "install_tax_price|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_tax_price|1"] ?? "");
    },
    "install_tax_price|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_tax_price|2"] ?? "");
    },
    "install_tax_price|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["install_tax_price|3"] ?? "");
    },
    "install_tax|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["install_tax|1"] ?? "");
    },
    "install_tax|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["install_tax|2"] ?? "");
    },
    "install_tax|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["install_tax|3"] ?? "");
    },
    "print_cost_without_tax|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_cost_without_tax|1"] ?? "");
    },
    "print_cost_without_tax|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_cost_without_tax|2"] ?? "");
    },
    "print_cost_without_tax|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_cost_without_tax|3"] ?? "");
    },
    "print_cost|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_cost|1"] ?? "");
    },
    "print_cost|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_cost|2"] ?? "");
    },
    "print_cost|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_cost|3"] ?? "");
    },
    "print_supplier|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_supplier|1"] ?? "");
    },
    "print_supplier|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_supplier|2"] ?? "");
    },
    "print_supplier|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_supplier|3"] ?? "");
    },
    "print_tax_price|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_tax_price|1"] ?? "");
    },
    "print_tax_price|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_tax_price|2"] ?? "");
    },
    "print_tax_price|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return UtilNumber.toPrice(price?.["print_tax_price|3"] ?? "");
    },
    "print_tax|1": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_tax|1"] ?? "");
    },
    "print_tax|2": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_tax|2"] ?? "");
    },
    "print_tax|3": (row) => {
        const prices = row?.prices ?? [];
        const price = prices[0] ?? {};
        return String(price?.["print_tax|3"] ?? "");
    },
};
