<template>
    <div class="layout-reconciliation-tab">
        <div
            class="layout-reconciliation-tab__section layout-reconciliation-tab__section_filter"
        >
            <ReconciliationFilter
                :filterItems="filterItems"
                :isLoading="isLoading"
                :pageType="pageType"
                :isCanCheck="isCanCheckTable"
                @onSelectFilterItem="onSelectFilterItem"
                @onRemoveAllFilterChips="onRemoveAllFilterChips"
            />
        </div>
        <slot
            :removeRows="removeRows"
            :onSaveChanges="onSaveRowChanges"
            :onFetchRowsOptions="onFetchRowsOptions"
            :onFetchRowOptions="onFetchRowOptions"
            :onChangeRowValue="onChangeRowValue"
            :onSetAllErrorsFilter="onSetAllErrorsFilter"
            :onSelectAllTableRows="onSelectAllTableRows"
            :onResetAllTableRows="onResetAllTableRows"
            :toggleRevisesAfter="toggleRevisesAfter"
        />
        <div
            :class="[
                'layout-reconciliation-tab__section_table layout-reconciliation-tab__table',
                { isApprovedTable: pageType === 'approved' },
            ]"
        >
            <ReconciliationTable
                :columns="tableColumns"
                :columnsShowed="tableColumnsShowed"
                :rows="tableRows"
                :isLoading="isLoading"
                :isRenderingTable="isRenderingTable"
                :isCanCheck="isCanCheckTable"
                :tableRowsSelectedIds="tableRowsSelectedIds"
                @onLazyload="onLazyloadTable"
                @toMore="toMoreTable"
                @toSendKit="toSendKit"
                @toFinishKit="toFinishKit"
                @toEditApprovedAndFact="toEditApprovedAndFact"
                @onChangeTableColumns="onChangeTableColumns"
                @onVisibleActionsApproved="onVisibleActionsApproved"
                @onChangeRowIsRevised="onChangeRowIsRevised"
                @onCheckRow="onCheckRow"
                @onCheckRowsAll="onCheckRowsAll"
                @clickMapMatchStatus="onClickMapMatchStatus"
            />
        </div>
        <ReconciliationModalSend
            v-if="kitIdSend !== ''"
            title="Создание предложения Oohdesk"
            :tags="offerTags"
            @onCloseModal="onCloseModalSendKit"
            @send="sendOffer"
        />
        <ReconciliationModalCheckAddressProgram
            v-if="isCheckAddressProgramModal"
            :isLoading.sync="isLoadingCheckAddressProgram"
            :contracts.sync="contracts"
            :infoMsg="checkAddressProgramInfoMsg"
            @checkAddressProgram="checkAddressProgram"
            @onCloseModal="onCloseModalCheckAddressProgram"
        >
            <RealtimeSystem
                :componentKey="'ReconciliationModalCheckAddressProgram'"
                :onAnyEventsSocketScheme="onAnyEventsSocketScheme"
                @endCheckAddressProgram="endCheckAddressProgram"
            />
        </ReconciliationModalCheckAddressProgram>
        <ReconciliationModalMatch 
            v-if="isShowModalMatch"
            :tableColumnsFromMatch="tableColumns"
            :tableColumnsToMatch="tableColumnsToMatch"
            :tableRowFromMatch="tableRowFromMatch"
            :tableRowsToMatch="tableRowsToMatch"
            :tableRowsToMatchSelected="tableRowsToMatchSelected"
            :isLoadingMatch="isLoadingMatch"
            @closeModal="onCloseModalMatch"
            @toStepMatchList="onToStepMatchList"
            @lazyloadTableToMatch="onLazyloadTableToMatch"
            @checkRowTableToMatch="onCheckRowTableToMatch"
            @runMatch="onRunMatch"
        />
    </div>
</template>

<script>
import ReconciliationFilter from "../components/Reconciliation/ReconciliationFilter/ReconciliationFilter.vue";
import ReconciliationTable from "../components/Reconciliation/ReconciliationTable/ReconciliationTable.vue";
import ReconciliationModalSend from "../components/MapV2/components/CreateModal/CreateModal.vue";
import ReconciliationModalCheckAddressProgram from "../components/Reconciliation/ReconciliationModals/ReconciliationModalCheckAddressProgram.vue";
import ReconciliationModalMatch from '../components/Reconciliation/ReconciliationModals/ReconciliationModalMatch.vue';
import RealtimeSystem from "../components/RealtimeSystem/RealtimeSystem.vue";
import ServiceReconciliation from "../services/ServiceReconciliationV2/ServiceReconciliation";
import ServiceReconciliationCheckAddressProgram from "../services/ServiceReconciliationV2/ServiceReconciliationCheckAddressProgram";
import ServiceReconciliationTransaction from '../services/ServiceReconciliationV2/ServiceReconciliationTransaction';
import ServiceReconciliationMatch from '../services/ServiceReconciliationV2/ServiceReconciliationMatch';

const ERRORS_FILTER_ID = 'error_filter'

export default {
    name: "LayoutReconciliationTab",
    components: {
        ReconciliationFilter,
        ReconciliationTable,
        ReconciliationModalSend,
        ReconciliationModalCheckAddressProgram,
        ReconciliationModalMatch,
        RealtimeSystem,
    },
    props: {
        kitId: {
            type: String,
            default: "",
        },
        pageType: {
            type: String,
            default: "offers",
            validator: (value) =>
                ["kits", "offers", "approved", "more", "fact"].includes(value),
        },
        canSendToFact: {
            type: Boolean,
            default: false,
        },
        canSendToBudget: {
            type: Boolean,
            default: false,
        },
        isCheckAddressProgramModal: {
            type: Boolean,
            default: false,
        },
        isCanCheckTable: {
            type: Boolean,
            default: false,
        },
        tableRowsSelectedLength: {
            type: Number,
            default: 0,
        },
        tableRowsToEdit: {
            type: Object,
            default: () => {},
        },
        tableRowToEdit: {
            type: String,
            default: "",
        },
        tableSelectedRowsIds: {
            type: Array,
            default: () => [],
        },
    },
    provide() {
        return {
            validationContractNumber: this.validationContractNumber,
        };
    },
    data: () => ({
        filterItems: [],
        tableColumns: [],
        tableRows: [],
        tableRowsSelected: [],
        tableSelectableRowsPriceIds: [],
        isLoadingFilter: false,
        isLoadingTable: false,
        isRenderingTable: false,
        tablePage: 0,
        tablePageCount: 0,
        kitIdSend: "",
        offerTags: [],
        contracts: {
            1: {
                value: "",
                isValid: true,
                errorMessage: "",
            },
        },
        isLoadingCheckAddressProgram: false,
        tableRowToEditId: "",
        editableRows: {},
        editedRows: {},
        onAnyEventsSocketScheme: [
            {
                id: "matchMapQueueJob",
                eventNameSocket: "match_map_queue_job",
                emitNameComponent: "endCheckAddressProgram",
            },
        ],
        checkAddressProgramInfoMsg: "",
        isShowModalMatch: false,
        tableRowFromMatch: {},
        tableSimilarSidesRequestBody: {
            page: 0,
            pageCount: 1,
            priceId: '',
            contractNumbers: []
        },
        tableColumnsToMatch: [],
        tableRowsToMatch: [],
        isLoadingMatch: false,
        tableRowsToMatchSelected: []
    }),
    computed: {
        isDisabledToFactBtn() {
            return !this.tableAgreedRows.length;
        },

        filterSelectedData() {
            const filterSelectedData = this.filterItems.map((filterItem) => {
                const { id: filterItemId = "", valuesSelected = [] } =
                    filterItem;
                const filterSelectedDataItem = {
                    id: String(filterItemId),
                    valuesSelected: [...valuesSelected],
                };

                return filterSelectedDataItem;
            });
            return filterSelectedData;
        },

        tableAgreedRows() {
            return this.tableRows.filter((row) => row.isVisibleActionsApproved);
        },

        isLoading() {
            return this.isLoadingFilter || this.isLoadingTable;
        },

        tableColumnsShowed() {
            return this.tableColumns.filter((tableColumn) =>
                Boolean(tableColumn?.isShow ?? false)
            );
        },

        kitNameSend() {
            const kitSend = this.tableRows.find(
                (row) => String(row?.id ?? "") === String(this.kitIdSend)
            );
            const kitNameSend = String(kitSend?.nameKit ?? "");

            return kitNameSend;
        },

        tableRowsSelectedIds() {
            return this.tableRowsSelected.map((tableRowSelected) =>
                String(tableRowSelected?.rowId ?? "")
            );
        },
    },
    mounted() {
        this.getData(true);
    },
    watch: {
        tableRows(newRows) {
            if (this.tableSelectableRowsPriceIds?.length) {
                this.setAllRowsSelected(newRows, this.tableSelectableRowsPriceIds)
            }
        },
        tableRowsSelectedIds(newValue) {
            const selectedRows =
                this.tableRows.filter((item) => newValue.includes(item.id)) ||
                [];
            const originalSelectedRows =
                selectedRows.map((row) => row.extraData.prices[0]) || [];
            const rowsGuids = originalSelectedRows.map(
                (item) => item?.id ?? ""
            );

            const tableRowsToEdit = {
                rowsIds: newValue,
                rowsGuids,
                editableFields: Object.keys(this.editableRows)
                    .map((rowKey) => {
                        let finalData = {};
                        const rowType = this.editableRows[rowKey];
                        const colToEdit = this.tableColumns.find(
                            (col) => col.apiProp === rowKey
                        );

                        if (!colToEdit) return null;

                        finalData = {
                            type: rowType,
                            prop: rowKey,
                            label: colToEdit.label,
                            value: "",
                        };

                        if (rowType === "select") {
                            finalData = {
                                ...finalData,
                                options: [],
                                page: 1,
                                totalCount: 0,
                            };
                        }

                        return finalData;
                    })
                    .filter((item) => !!item),
            };

            this.$emit("update:tableRowsToEdit", tableRowsToEdit);
        },
        tableRowToEditId(rowId) {
            const selectedRow =
                this.tableRows.find((item) => item.id === rowId) || {};
            const originalSelectedRow = selectedRow.extraData.prices[0] || {};

            const tableRowsToEdit = {
                rowsIds: [rowId],
                rowsGuids: [originalSelectedRow?.id ?? ""],
                editableFields: Object.keys(this.editableRows)
                    .map((rowKey) => {
                        let finalData = {};
                        const rowType = this.editableRows[rowKey];
                        const colToEdit = this.tableColumns.find(
                            (col) => col.apiProp === rowKey
                        );

                        if (!colToEdit) return null;

                        finalData = {
                            type: rowType,
                            prop: rowKey,
                            label: colToEdit.label,
                            value: originalSelectedRow[rowKey] ?? "",
                        };

                        if (rowType === "select") {
                            finalData = {
                                ...finalData,
                                options: [],
                                page: 1,
                                totalCount: 0,
                            };
                        }

                        return finalData;
                    })
                    .filter((item) => !!item),
            };

            this.$emit("update:tableRowsToEdit", tableRowsToEdit);
        },
        async canSendToFact(value) {
            value && (await this.onSendToFact());
        },
        async canSendToBudget(value) {
            value && (await this.onSendToBudget());
        },
        isDisabledToFactBtn(value) {
            this.$emit("onChangeDisableBtn", value);
        },
        tableRowsSelected(newValue) {
            this.$emit("update:tableRowsSelectedLength", newValue.length);
        },
        tableSelectableRowsPriceIds(newValue) {
            this.$emit("update:tableRowsSelectedLength", newValue.length);
        },
    },
    methods: {
        async getData(isRerunFilter = false) {
            this.isLoadingFilter = true;
            this.isLoadingTable = true;
            this.isRenderingTable = true;
            this.tablePage = 0;
            this.tablePageCount = 0;
            this.tableColumns = [];
            this.tableRowsSelected = [];

            await this.getFilterData();

            if (isRerunFilter) await this.getFilterData();

            await this.getTableData((pageCount, rows, columns) => {
                this.tablePageCount = pageCount;
                this.tableColumns = [...columns];
                this.tableRows = rows.map((row) => ({
                    ...row,
                    isVisibleActionsApproved: false,
                }));
                this.isRenderingTable = false;
            });

            this.$emit("onFilterData", this.filterItems);
        },

        setAllRowsSelected(rows, tableSelectableRowsPriceIds) {
            this.tableRowsSelected = tableSelectableRowsPriceIds.reduce((resIds, rowId) => {
                rows.forEach(row => {
                    const { id = "", extraData = {} } = row;
                    const { prices = [] } = extraData;
                    const pricesIds = prices.map((price) => String(price?.id ?? ""));

                    if (pricesIds.includes(rowId)) {
                        return resIds.push({ rowId: String(id), pricesIds });
                    }
                })
                    return resIds
            }, [])
        },

        async onSaveRowChanges() {
            Object.keys(this.editedRows).forEach(async (field) => {
                const value = this.editedRows[field];

                await ServiceReconciliation.postTransactionsEdit({
                    transactions: this.tableRowsToEdit.rowsGuids,
                    edit_field: { field, value },
                });

                this.editedRows = {};
                this.getData(false);
            });
        },

        onChangeRowValue(prop, value) {
            this.editedRows[prop] = value;
        },

        async onFetchRowsOptions(select) {
            await this.onFetchRowOptions(select, true);
        },

        async onFetchRowOptions(select, isMassEdit) {
            let reqBody = {
                field: String(select.prop ?? ""),
            };

            if (select.prop.includes("entity")) {
                const selectedRows = this.tableRows.filter((item) => {
                    if (isMassEdit) {
                        return item.id === this.tableRowToEditId;
                    }
                    return this.tableSelectedRowsIds.includes(item.id);
                });

                if (select.prop === "supplier_entity") {
                    reqBody = {
                        ...reqBody,
                        supplier: String(selectedRows[0]?.owner ?? ""),
                    };
                } else {
                    const firstSplit = select.prop.split("|");
                    const secondSplit = firstSplit[0].split("_");
                    const resultKey = String(
                        `${secondSplit[0]}_${secondSplit[1]}|${firstSplit[1]}` ??
                            ""
                    );

                    reqBody = {
                        ...reqBody,
                        supplier: String(selectedRows[0][resultKey] ?? ""),
                    };
                }
            }

            await ServiceReconciliation.getTransactionsSelectOptions(
                reqBody,
                (result) => {
                    const editableFields =
                        this.tableRowsToEdit.editableFields.map((item) => {
                            if (item.prop === select.prop) {
                                item.options = result.items;
                                item.totalCount = result.total_count;

                                const optionValue = result.items.find(
                                    (option) => option.value === reqBody.query
                                );
                                if (optionValue) item.value = optionValue.id;
                            }
                            return item;
                        });
                    this.$emit("update:tableRowsToEdit", {
                        ...this.tableRowsToEdit,
                        editableFields,
                    });
                }
            );
        },

        async getFilterData() {
            await ServiceReconciliation.getFilter(
                this.pageType,
                this.filterSelectedData,
                this.kitId,
                (filterItems) => {
                    this.filterItems = [...filterItems];
                    this.isLoadingFilter = false;
                },
                () => (this.isLoadingFilter = false)
            );
        },

        async getTableData(actionAfter = () => {}) {
            await ServiceReconciliation.getTable(
                this.pageType,
                this.filterSelectedData,
                this.kitId,
                this.tablePage,
                (tableData) => {
                    const {
                        pageCount = 0,
                        rows = [],
                        columns = [],
                        editable = {},
                    } = tableData;
                    this.editableRows = editable;
                    this.tablePage += 1;
                    //#отпускпаши Привет, Паш. Я тут насрал
                    rows.forEach((row) => {
                        if(Array.isArray(row?.priceGroupTags || null)){
                            let tagsString = row.priceGroupTags.map((priceGroupTag) => String(priceGroupTag?.value ?? ""))
                                .join(", ");
                            row.priceGroupTags = tagsString;
                        }
                    })
                    actionAfter(pageCount, rows, columns);
                    this.isLoadingTable = false;
                },
                () => (this.isLoadingTable = false)
            );
        },

        async onLazyloadTable() {
            if (this.tablePage < this.tablePageCount) {
                this.isLoadingTable = true;
                await this.getTableData((_, rows) =>
                    this.tableRows.push(...rows)
                );
                this.isLoadingTable = false;
            }
        },

        async setTableColumnsSchemeData(tableColumns = []) {
            ServiceReconciliation.setTableColumnsSchemeData(
                this.pageType,
                tableColumns
            );
        },

        async getOfferTags(actionAfter = () => {}) {
            await ServiceReconciliation.getOfferTags(actionAfter, () => {});
        },

        async postSendOffer(loginUser = "", offerTagsSelected = []) {
            await ServiceReconciliation.postOfferSend(
                this.kitIdSend,
                this.kitNameSend,
                loginUser,
                offerTagsSelected,
                () => {
                    this.onCloseModalSendKit();
                    alert("Предложение отправлено");
                },
                (errorEmail) => {
                    this.onCloseModalSendKit();
                    if (String(errorEmail) !== "") alert(String(errorEmail));
                }
            );
        },

        async postChangeState(rowId = "", priceId = "", agree = false) {
            await ServiceReconciliation.postChangeState(
                priceId,
                agree,
                () => {
                    const rowIndex = this.tableRows.findIndex(
                        (row) => String(row?.id ?? "") === String(rowId)
                    );
                    this.tableRows.splice(rowIndex, 1);

                    this.$emit("onChangeState", this.filterItems);
                },
                (error) => alert(error)
            );
        },

        async onSendToFact() {
            let priceIds = []

            if (this.tableSelectableRowsPriceIds.length >= this.tableRowsSelected.length) {
                priceIds = this.tableSelectableRowsPriceIds
            } else {
                priceIds = this.tableRowsSelected.reduce((pricesIds, item) => {
                    pricesIds.push(...item.pricesIds)
                    return pricesIds
                }, [])
            }

            await ServiceReconciliation.postChangeState(priceIds, false, () => {
                this.tableRowsSelected.forEach(item => {
                    const rowIndex = this.tableRows.findIndex(
                        (row) => String(row?.id ?? "") === String(item.rowId)
                    );
                    this.tableRows.splice(rowIndex, 1);
                })

                this.$emit("onChangeState", this.filterItems);
            }, (error) => alert(error));

            this.$emit('onSendedToFact')
        },

        async onSendToBudget() {
            await this.sendBudget(this.filterSelectedData);
            this.$emit('onSendedToBudget')
        },

        async sendBudget(filterData = []) {
            try {
                await ServiceReconciliation.sendBudget(filterData);
                alert("Данные успешно отправлены");
            } catch (error) {
                alert(error);
            }
        },

        onVisibleActionsApproved(rowId = "") {
            this.tableRows = this.tableRows.map((row) => {
                if (String(row?.id ?? "") === String(rowId)) {
                    row.isVisibleActionsApproved =
                        !row.isVisibleActionsApproved;
                }
                return row;
            });
        },

        onChangeRowIsRevised(rowId = "", priceId = "") {
            this.toggleRevise(rowId, priceId);
        },

        async toggleRevise(rowId = "", priceId = "") {
            try {
                const isRevised = await ServiceReconciliation.toggleRevise(
                    priceId
                );
                this.toggleReviseAfter(rowId, isRevised);
            } catch (error) {
                console.log(error);
            }
        },

        toggleReviseAfter(rowId = "", isRevised = false) {
            const rowIndex = this.tableRows.findIndex(
                (row) => String(row?.id ?? "") === String(rowId)
            );
            this.tableRows.splice(rowIndex, 1, {
                ...this.tableRows[rowIndex],
                isRevised: Boolean(isRevised),
            });
        },

        toggleRevisesAfter(rowIds = [], isRevised = false) {
            rowIds.forEach(rowId => this.toggleReviseAfter(rowId, isRevised));
        },

        onSelectFilterItem(filterComponentItems = [], filterItemId = "") {
            const filterItemIndex = this.filterItems.findIndex(
                (filterItem) => String(filterItem?.id) === String(filterItemId)
            );
            if (filterItemIndex !== -1) {
                this.filterItems.splice(filterItemIndex, 1, {
                    ...this.filterItems[filterItemIndex],
                    valuesSelected: [...filterComponentItems],
                });
            }

            this.getData(false);
        },

        onSetAllErrorsFilter() {
            const errorsValues = this.filterItems
                .map(item => {
                    if (item.id === ERRORS_FILTER_ID) {
                        return item.values.map(item => item.id)
                    }
                })
                .filter(item => !!item)

            this.onSelectFilterItem(...errorsValues, ERRORS_FILTER_ID)
        },

        onRemoveAllFilterChips(filterItems = []) {
            this.filterItems = [...filterItems];

            this.getData(false);
        },

        toMoreTable(kitId = "") {
            const kitMore = this.tableRows.find(
                (row) => String(row?.id ?? "") === String(kitId)
            );
            const kitNameMore = String(kitMore?.nameOffer ?? "");
            this.$router.push({
                name: "reconciliationMore",
                query: { kitId: String(kitId), kitTitle: String(kitNameMore) },
            });
        },

        toSendKit(kitId = "") {
            this.getOfferTags((offerTags) => {
                this.offerTags = [...offerTags];
                this.kitIdSend = String(kitId);
            });
        },

        toFinishKit(kitId = ""){
            if(!confirm("Вы уверены что хотите отправить согласованные конструкции набора в предфакт?")){
                return false;
            }
            this.postFinishOffer(kitId);
        },

        async postFinishOffer(kitId) {
            await ServiceReconciliation.postOfferFinish(
                kitId,
                () => {
                    this.tableRows.filter((row) => row.id = kitId).forEach((row) => {
                        row.extraData.is_finish_approved = true;
                    })
                    // alert("Предложение отправлено в предфакт");
                },
                (errorEmail) => {
                    if (String(errorEmail) !== "") alert(String(errorEmail));
                }
            );
        },

        toEditApprovedAndFact(kitId = "") {
            this.tableRowToEditId = kitId;
            this.$emit("onOpenSingleEditModal");
        },

        onCloseModalSendKit() {
            this.kitIdSend = "";
            this.offerTags = [];
        },

        onChangeTableColumns(tableColumns = []) {
            this.tableColumns = [...tableColumns];

            this.setTableColumnsSchemeData(tableColumns);
        },

        sendOffer(loginUser = "", offerTagsSelected = []) {
            this.postSendOffer(loginUser, offerTagsSelected);
        },

        onCloseModalCheckAddressProgram() {
            this.$emit("onCloseModalCheckAddressProgram");
            this.contracts = {
                1: {
                    value: "",
                    isValid: true,
                    errorMessage: "",
                },
            };
            this.checkAddressProgramInfoMsg = "";
        },

        async validationContractNumber(contractValue = "") {
            return await ServiceReconciliationCheckAddressProgram.validationContractNumber(
                contractValue
            );
        },

        async checkAddressProgram(contractsValues = []) {
            // this.onCloseModalCheckAddressProgram();
            this.isLoadingCheckAddressProgram = true;
            await ServiceReconciliationCheckAddressProgram.checkAddressProgram(
                contractsValues,
                this.filterSelectedData
            );
        },

        endCheckAddressProgram(data = {}) {
            const { summary = {} } = data;
            const { found = 0, notFound = 0 } = summary;
            this.checkAddressProgramInfoMsg = `Сверено: ${found}; не сверено: ${notFound}`;
            this.isLoadingCheckAddressProgram = false;
        },

        onCheckRow(checkRowData = {}) {
            const { row = {}, rowIsSelected = false } = checkRowData;
            const { id = "" } = row;

            if (rowIsSelected)
                this.tableRowsSelected.push(this.getRowSelected(row));
            else {
                const rowIndex = this.tableRowsSelected.findIndex(
                    (rowSelected) => String(rowSelected?.rowId) === String(id)
                );
                if (rowIndex !== -1) this.tableRowsSelected.splice(rowIndex, 1);
            }
        },

        onCheckRowsAll(isSelected = false) {
            // if (isSelected)
            //     this.tableRowsSelected = this.tableRows.map((row) =>
            //         this.getRowSelected(row)
            //     );
            // else this.tableRowsSelected = [];
            if (isSelected)
                this.onSelectAllTableRows();
            else
                this.onResetAllTableRows();

        },

        getRowSelected(row = {}) {
            const { id = "", extraData = {} } = row;
            const { prices = [] } = extraData;
            const pricesIds = prices.map((price) => String(price?.id ?? ""));

            return { rowId: String(id), pricesIds };
        },

        removeRows() {
            this.transactionsRemove();
        },

        onSelectAllTableRows() {
            ServiceReconciliationTransaction.postIds(this.filterSelectedData, this.pageType, rowsIds => {
                this.tableSelectableRowsPriceIds = rowsIds;
                this.setAllRowsSelected(this.tableRows, rowsIds);
            })
        },

        onResetAllTableRows() {
            // this.onCheckRowsAll(false);
            this.tableRowsSelected = [];
            this.tableSelectableRowsPriceIds = [];
        },

        async transactionsRemove() {
            let priceIds = []

            if (this.tableSelectableRowsPriceIds.length >= this.tableRowsSelected.length) {
                priceIds = this.tableSelectableRowsPriceIds
            } else {
                priceIds = this.tableRowsSelected.reduce(
                    (priceIds, tableRowSelected) => {
                        const { pricesIds = [] } = tableRowSelected;
                        priceIds.push(
                            ...pricesIds.map((priceId) => String(priceId))
                        );

                        return priceIds;
                    },
                    []
                );
            }

            await ServiceReconciliation.transactionsRemove(priceIds);

            const rowsIdsSelected = this.tableRowsSelected.map(
                (tableRowSelected) => String(tableRowSelected?.rowId ?? "")
            );
            this.tableRows = this.tableRows.filter(
                (row) => !rowsIdsSelected.includes(String(row?.id ?? ""))
            );
            this.tableRowsSelected = [];

            this.tableRows = [];
            this.tablePage = 0;
            this.isLoadingTable = true;
            this.isRenderingTable = true;
            await this.getTableData((pageCount, rows) => {
                this.tablePageCount = pageCount;
                this.tableRows = rows.map((row) => ({
                    ...row,
                    isVisibleActionsApproved: false,
                }));
            });
            this.isRenderingTable = false;

            this.$emit("onTransactionsRemove", this.filterItems);
        },
        onClickMapMatchStatus(slotTableData = {}) {
            this.isShowModalMatch = true;
            this.tableRowFromMatch = slotTableData?.row ?? {};
            const price = slotTableData?.row?.extraData?.prices[0] ?? {};
            this.tableSimilarSidesRequestBody.priceId = String(price?.id ?? '');
        },
        onCloseModalMatch() {
            this.isShowModalMatch = false;
            this.tableRowFromMatch = {};
            this.tableSimilarSidesRequestBody = {
                page: 0,
                pageCount: 1,
                priceId: '',
                contractNumbers: []
            };
        },
        onToStepMatchList(contractNumbers = []) {
            this.tableSimilarSidesRequestBody.contractNumbers = [...contractNumbers];
            this.getTableSimilarSides();
        },
        onLazyloadTableToMatch() {
            this.getTableSimilarSides();
        },
        async getTableSimilarSides() {
            const { page, priceId, contractNumbers, pageCount } = this.tableSimilarSidesRequestBody;

            if (page < pageCount) {
                this.isLoadingMatch = true;

                const tableDataToMatch = await ServiceReconciliationMatch.getTableSimilarSides(page + 1, priceId, contractNumbers);
    
                this.tableColumnsToMatch = tableDataToMatch?.tableColumn ?? [];
                const tableRowsToMatch = tableDataToMatch?.tableRows ?? [];
                this.tableRowsToMatch.push(...tableRowsToMatch);

                this.tableSimilarSidesRequestBody.page += 1;
                this.tableSimilarSidesRequestBody.pageCount = tableDataToMatch?.pageCount ?? 1;

                this.isLoadingMatch = false;
            }
        },
        onCheckRowTableToMatch(rowId = '', isChecked = false) {
            if (isChecked)
                this.tableRowsToMatchSelected = [String(rowId)];
            else
                this.tableRowsToMatchSelected = [];
        },
        onRunMatch() {
            this.runMatch();
        },
        async runMatch() {
            const priceId = String(this.tableSimilarSidesRequestBody?.priceId ?? '');
            const mapPriceId = String(this.tableRowsToMatchSelected[0] ?? '');
            
            await ServiceReconciliationMatch.runMatch(priceId, mapPriceId);

            await this.getData(false);
            this.onCloseModalMatch();
        }
    },
};
</script>

<style lang="scss" scoped>
.layout-reconciliation-tab {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0px -40px 0px -40px;
    padding: 0 40px 40px 40px;

    &__section {
        &_table {
            flex: 1;
            margin-top: 10px;
        }

        &_filter {
            background: #fff;
            margin: 0px -40px 0px -40px;
            padding: 20px 40px 30px 40px;
        }
    }

    &__table {
        &-settings {
            display: flex;
            justify-content: end;
        }
    }
}
</style>
