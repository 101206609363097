<template>
    <AppTable
        primaryKey="id"
        :columns="columnsShowed"
        :rows="rows"
        :isProgressBar="isLoading"
        :isRenderingTable="isRenderingTable"
        :canCheck="isCanCheck"
        :canCheckAll="isCanCheckAll"
        :rowsSelected="tableRowsSelectedIds"
        :isStickyCheckbox="true"
        @lazyload="onLazyload"
        @onCheckRow="onCheckRow"
        @onCheckRowsAll="onCheckRowsAll"
        class="reconciliation-table"
    >
        <template
            v-for="(column, index) in columnsSlotHeader"
            v-slot:[column]
		>
            <TableSettings
                :key="index"
                :tableColumns="columns"
                @onChangeTableColumns="onChangeTableColumns"
            />
        </template>

        <template v-slot:nameOffer="scopeData">
            <div
                class="reconciliation-table__name-offer"
                @click="toMore(scopeData)"
            >
                {{getRowNameOffer(scopeData)}}
            </div>
        </template>

        <template v-slot:actionsKit="scopeData">
            <div
                class="reconciliation-table__name-offer"
                @click="toSendKit(scopeData)"
            >
                Отправить
            </div>
        </template>

        <template v-slot:actionsOffer="scopeData">
            <div style="display: flex">
                <div
                    v-if="isFinished(scopeData) && !isFinishApproved(scopeData)"
                    class="reconciliation-table__name-offer"
                    @click="toFinishKit(scopeData)"
                >
                    Отправить в предфакт
                </div>
                <div
                    v-else-if="isFinished(scopeData) && isFinishApproved(scopeData)"
                >
                    Отправлено в предфакт
                </div>
            </div>
        </template>

        <template v-slot:actionsApproved="scopeData">
            <div
                class="reconciliation-table__actions-approved"
                @click="toEditApprovedAndFact(scopeData)"
            >
                <IconEdit />
            </div>
        </template>

        <template v-slot:agree="scopeData">
            <BaseCheckboxV3
                :key="getRowId(scopeData)"
                :checked="getRowAgree(scopeData)"
                :size="20"
                @change="onChangeRowActionsApproved(scopeData)"
            />
        </template>

        <template v-slot:isRevised="scopeData">
            <BaseCheckboxV3
                :key="getRowId(scopeData)"
                :checked="getRowIsRevised(scopeData)"
                :size="20"
                :isDisabled="false"
                @change="onChangeRowIsRevised(scopeData)"
            />
        </template>

        <template v-slot:status="scopeData">
            <div class="reconciliation-table__status">
                <div
                    class="reconciliation-table__status_preview"
                    :style="{'background-color': getRowStatusColor(scopeData)}"
                />
                <div class="reconciliation-table__status_value">
                    {{getRowStatus(scopeData)}}
                </div>
            </div>
        </template>

        <template v-slot:statusUser="scopeData">
            <div class="reconciliation-table__status">
                <div
                    class="reconciliation-table__status_preview"
                    :style="{'background-color': getRowStatusUserColor(scopeData)}"
                />
                <div class="reconciliation-table__status_value">
                    {{getRowStatusUser(scopeData)}}
                </div>
            </div>
        </template>

        <template v-slot:mapMatchStatus="scopeData">
            <div 
                class="reconciliation-table__map-match-status"
                :style="{ 'border-color': getRowMapMatchStatusColor(scopeData) }"
                @click="onClickMapMatchStatus(scopeData)"
            >
                {{ scopeData.row.mapMatchStatus }}
            </div>
        </template>
    </AppTable>
</template>

<script>
import AppTable from '../../Table2/Table.vue';
import BaseCheckboxV3 from '../../Base/BaseCheckboxV3.vue';
import TableSettings from '../../Table2/TableSettings.vue';
import IconEdit from '../../Icons/IconEdit.vue'
export default {
    name: 'ReconciliationTable',
    components: {
    AppTable,
    BaseCheckboxV3,
    TableSettings,
    IconEdit
},
    props: {
        columns: {
            type: Array,
            default: () => ([])
        },
        columnsShowed: {
            type: Array,
            default: () => ([])
        },
        rows: {
            type: Array,
            default: () => ([])
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isRenderingTable: {
            type: Boolean,
            default: false
        },
        isCanCheck: {
            type: Boolean,
            default: false
        },
        isCanCheckAll: {
            type: Boolean,
            default: true
        },
        tableRowsSelectedIds: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        columnsSlotHeader() {
            return this.columns.filter(column => Boolean(column?.isSlotHeader ?? false)).map(column => `header-${column?.prop ?? ''}`);
        },
        columnsForSettings() {
            return this.columns.filter(item => !item?.isAction)
        }
    },
    methods: {
        onLazyload() {
            this.$emit('onLazyload');
        },

        onCheckRow(checkRowData = {}) {
            this.$emit('onCheckRow', checkRowData);
        },

        onCheckRowsAll(isSelected = false) {
            this.$emit('onCheckRowsAll', isSelected);
        },

        getRowProp(scopeData = {}, propKey = '') {
            const row = scopeData?.row ?? {};
            return row[propKey] ?? null;
        },

        getRowPriceProp(scopeData = {}, propKey = '') {
            const extraData = this.getRowProp(scopeData, 'extraData') ?? {};
            const prices = extraData?.prices ?? [];
            const price = prices[0] ?? {};

            return price[propKey] ?? null;
        },

        toMore(scopeData = {}) {
            const id = String(this.getRowProp(scopeData, 'id') ?? '');
            this.$emit('toMore', id);
        },

        toSendKit(scopeData = {}) {
            const id = String(this.getRowProp(scopeData, 'id') ?? '');
            this.$emit('toSendKit', id);
        },

        toFinishKit(scopeData = {}) {
            const id = String(this.getRowProp(scopeData, 'id') ?? '');
            this.$emit('toFinishKit', id);
        },

        toEditApprovedAndFact(scopeData = {}) {
            const id = String(this.getRowProp(scopeData, 'id') ?? '');
            this.$emit('toEditApprovedAndFact', id);
        },

        getRowId(scopeData = {}) {
            return String(this.getRowProp(scopeData, 'id') ?? '');
        },

        getRowExtraProp(scopeData = {}, propKey = '') {
            const extraData = this.getRowProp(scopeData, 'extraData') ?? {};
            return extraData[propKey] ?? null;
        },

        isFinished(scopeData = {}) {
            return this.getRowExtraProp(scopeData, 'finished_pg_id') ?? false;
        },

        isFinishApproved(scopeData = {}) {
            return this.getRowExtraProp(scopeData, 'is_finish_approved') ?? false;
        },

        canApproveFinished(scopeData = {}) {
            const extraData = this.getRowProp(scopeData, 'extraData') ?? {};
            const isFinished = extraData?.finished_pg_id ?? false;
            const isFinishedApproved = extraData?.is_finish_approved ?? false;

            return isFinished && !isFinishedApproved;
        },

        getRowNameOffer(scopeData = {}) {
            return String(this.getRowProp(scopeData, 'nameOffer') ?? '');
        },

        getRowStatus(scopeData = {}) {
            return String(this.getRowProp(scopeData, 'status') ?? '');
        },

        getRowStatusUser(scopeData = {}) {
            return String(this.getRowProp(scopeData, 'statusUser') ?? '');
        },

        getRowStatusColor(scopeData = {}) {
            return `#${String(this.getRowPriceProp(scopeData, 'status_color') ?? '')}`;
        },

        getRowStatusUserColor(scopeData = {}) {
            return `#${String(this.getRowPriceProp(scopeData, 'user_status_color') ?? '')}`;
        },

        getRowMapMatchStatusColor(scopeData = {}) {
            const mapMatchStatusCodes = {
                0: '#FFB300',
                1: '#6EC87A',
                2: '#F84967'
            };

            const price = scopeData?.row?.extraData?.prices[0] ?? {};
            const mapMatchStatusCode = parseInt(price?.map_match_status ?? -1);

            return mapMatchStatusCodes[mapMatchStatusCode] ?? '';
        },

        getRowAgree(scopeData = {}) {
            return Boolean(this.getRowProp(scopeData, 'agree') ?? false);
        },

        getRowIsRevised(scopeData = {}) {
            return Boolean(this.getRowProp(scopeData, 'isRevised') ?? false);
        },

        onChangeRowActionsApproved(scopeData = {}) {
            const rowId = this.getRowId(scopeData);
            this.$emit('onVisibleActionsApproved', rowId)
        },

        onChangeRowIsRevised(scopeData = {}) {
            const priceId = String(this.getRowPriceProp(scopeData, 'id') ?? '');
            const rowId = this.getRowId(scopeData);

            this.$emit('onChangeRowIsRevised', rowId, priceId);
        },

        onChangeTableColumns(tableColumns = []) {
            this.$emit('onChangeTableColumns', tableColumns);
        },

        onClickMapMatchStatus(scopeData = {}) {
            this.$emit('clickMapMatchStatus', scopeData);
        }
    }
}
</script>

<style lang="scss" scoped>
    .reconciliation-table {
        width: 100%;
        height: auto;
        max-height: 100vh;

        ::v-deep .header {
            .offer-price-cell .cell__content span {
                white-space: pre-wrap !important;
                text-align: right !important;
            }
        }

        ::v-deep .header .map-match-status {
            padding: 3px 15px !important;
        }

        &__map-match-status {
            width: 100%;
            height: 100%;
            padding: 13px 15px;
            display: flex;
            align-items: center;
            border: 2px solid;
            cursor: pointer;
        }

        &__name-offer {
            color: #4A91F1;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 3px;
        }

        &__status {
            display: flex;
            &_preview {
                border-radius: 100%;
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }

        &__actions-approved {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 34px;
            height: 36px;
            border-radius: 4px;
            cursor: pointer;
            transition: all .4s ease-out;

            &:hover {
                background-color: #f2f2f2;
            }

            & svg {
                width: 18px;
                height: 18px;
            }
        }


        &__end-edit {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;
            position: relative;
            transition: background-color .6s ease-out;
            margin-right: 8px;

            &-icon {
                width: 12px;
                height: 12px;

                ::v-deep path {
                    fill: #aab0b8;
                }

                &:hover {
                    ::v-deep path {
                        fill: #000;
                    }
                }
            }
        }

        &__label-checked {
            display: flex;
            position: absolute;
            top: -2px;
            right: -2px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #6ec87a;

            &-icon {
                width: 8px;
                height: 8px;

                ::v-deep path {
                    fill: white;
                }
            }
        }


    }
    ::v-deep .cell__content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>
