import ServiceApi from '../ServiceApi';
import ServiceReconciliationFilter from './ServiceReconciliationFilter';
export default class ServiceReconciliationSummary {
    static _microserviceName = 'free';

    static _requestRouts = {
        getSummary: {
            more: '/new_map/offer-side-summary',
            approved: '/new_map/approve-summary',
            fact: '/new_map/done-summary'
        }
    };

    static _summaryItemsScheme = [
        {
            id: 'sumCost',
            apiKey: 'sum_cost',
            title: 'Общий бюджет с НДС',
            totalType: 'price',
            // emit: 'onDetalization',
            // action: 'Детализация'
        },
        {
            id: 'sumCostWithoutTax',
            apiKey: 'sum_cost_without_tax',
            title: 'Общий бюджет без НДС',
            totalType: 'price',
            // emit: 'onDetalization',
            // action: 'Детализация'
        },
        {
            id: 'totalCount',
            apiKey: 'item_count',
            title: 'Найдено',
            totalType: 'count',
        },
        {
            id: 'oohdeskTotalCount',
            apiKey: 'oohdesk_count',
            title: 'Всего в Oohdesk',
            totalType: 'count',
        },
        // {
        //     id: 'sumCostWithoutTax',
        //     apiKey: 'sum_cost_without_tax',
        //     title: 'Общий бюджет без НДС',
        //     totalType: 'price'
        // },
        // {
        //     id: 'itemCount',
        //     apiKey: 'item_count',
        //     title: 'Кол-во сторон',
        //     totalType: 'count'
        // },
        {
            id: 'countMapRevised',
            apiKey: 'count_map_revised',
            title: 'Не сверено с Oohdesk',
            totalType: 'count',
            emit: 'onMatch',
            action: 'Сверить',
            alarm: 'Количество конструкций, которые не удалось найти при сверке c адресной программой в Oohdesk'
        },
        {
            id: 'countSupplierRevised',
            apiKey: 'count_supplier_revised',
            title: 'Не сверено с операторами',
            totalType: 'count'
        },
        {
            id: 'validCount',
            apiKey: 'valid_count',
            title: 'Стороны с ошибками',
            totalType: 'count',
            emit: 'onErrorsFilter',
            action: 'Показать'
        },
        // {
        //     id: 'successCount',
        //     apiKey: 'success_count',
        //     title: 'Кол-во сторон со всей информацией',
        //     totalType: 'count'
        // },
    ]

    static async getSummary(pageType = '', filterData = [], kitId = '', actionAfter = () => {}, actionError = () => {}) {
        await this._getSummary(pageType, filterData, kitId, actionAfter, actionError);
    }

    static async _getSummary(pageType = '', filterData = [], kitId = '', actionAfter = () => {}, actionError = () => {}) {
        const reqBody = ServiceReconciliationFilter.getFilterBefore(filterData, kitId);

        try {
            const res = await ServiceApi.post(this._microserviceName, this._requestRouts.getSummary[pageType] ?? '', reqBody)
            const {data = {}} = res;
            const {data: resBody = {}} = data;

            const summaryData = this._getSummaryAfter(resBody, pageType);
            actionAfter(summaryData);
        } catch (error) {
            console.log(error);
            actionError(error);
        }
    }

    static _getSummaryAfter(resBody = {}) {
        return {
            itemCount: Number(resBody?.item_count) || 0,
            items: this._summaryItemsScheme.map(summaryItemScheme => ({...summaryItemScheme, total: parseFloat(resBody[summaryItemScheme?.apiKey ?? ''] ?? 0)}))
        }
    }
}
